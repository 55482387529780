<template>
    <v-row no-gutters class="d-block">
        <v-col cols="12">
            <v-card>

                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-row>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">

                                <v-alert dense type="warning" outlined class="text-center mt-3">
                                    Selecciona las Unidades Operativas para asignarle permisos y pulsa en Guardar
                                </v-alert>

                                <div v-for="(uo) in unidadesOperativasUsuarios" :key="uo.unidad_operativa_id" class="">
                                    <v-divider class=""></v-divider>
                                    <v-checkbox class="d-inline-flex font-weight-bold"
                                                v-model="uo.activo"
                                                :value="uo.activo"
                                                :label="uo.unidad_operativa"
                                                hide-details
                                    ></v-checkbox>
                                </div>

                            </v-col>

                        </v-row>

                    </v-form>
                </v-card-text>
                <v-divider class="mt-1"></v-divider>
                <v-card-actions class="justify-center py-3">
                    <v-btn outlined @click="regresar" small>
                        <v-icon>arrow_back</v-icon>
                        Regresar
                    </v-btn>

                    <v-btn outlined color="warning" @click="reset" small>
                        <v-icon>restart_alt</v-icon>
                        Limpiar
                    </v-btn>

                    <v-btn color="success" :loading="loading" @click="savePermiso" small>
                        <v-icon>save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "ParticipantesAdministradoresPermisos",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            unidadesOperativasUsuarios: [],
            permisoModel: {
                persona_id: this.getParams.persona_id,
            },
            usuarioForm: {},
        }
    },
    computed: {
        // DO some
    },
    methods: {
        regresar() {
            this.$router.go(-1)
        },
        reset() {
            // Reset los valores del usuario
            this.getUsuarioCreate();
        },
        getUsuarioCreate() {
            this.loading = true;

            // Get data;
            let params = {persona_id: this.getParams.persona_id}

            window.axios.get('/unidades_operativas_usuarios', {params}).then(response => {
                if (response.data.success) {

                    this.unidadesOperativasUsuarios = response.data.data;

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener los datos para guardar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
        savePermiso() {
            // Validar
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url = '/unidades_operativas_usuarios/save';
                let params = {permisos: this.unidadesOperativasUsuarios}

                window.axios.post(url, params).then(response => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);

                        // Mandar a traer los datos guardados
                        this.getUsuarioCreate();

                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo guardar");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            } else {
                this.$toast.warning("Complete toda la información requerida.");
            }

        },

    },
    created() {
        this.getUsuarioCreate();
    }
}
</script>

<style>

</style>
